import React from "react";
import styles from './NewsSection.module.css';
import Ceo from '../../../assets/images/newsSection/Ceo.png';

const NewsSection = () => {
    return (
        <div className={styles.newsSection}>
            <div className={styles.container}>
                <img className={styles.ceoImage} src={Ceo} />
                <div className={styles.content}>
                    <p className={styles.writer}>
                        [제2서울핀테크랩 스타트업 CEO]
                    </p>
                    <p className={styles.title}>
                        AI 재무설계 및 전문가 매칭<br />
                        ESG 인슈어테크 서비스하는 ‘인톡’
                    </p>
                    <p className={styles.description}>
                        [한경잡앤조이=이진호 기자] 인톡은 인슈어테크 스타트업이다. 유인창 대표(33)가 2022년 11월에 설립했다. 유 대표는 “인톡은 돈의 노예가 아니라 인생의 주인으로 살기 위해 삶의 에너지를 관리해주는 회사”라며 “사회 초년생, 중장년 등 금융소외 계층을 위한 개인 맞춤형 시뮬레이션 기반 AI 재무설계 및 전문가 매칭 ESG 핀테크 서비스...
                    </p>
                    <div className={styles.goToNewsButton} onClick={() => { window.location.href = 'https://magazine.hankyung.com/job-joy/article/202310062089d'; }}>
                        뉴스보기
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsSection;