import React from 'react';
import styles from './CTAButton.module.css';

const CTAButton = ({text, backgroundColor, textColor, onClickEvent}) =>{
    return (
        <div className={styles.ctaButton} style={{
            backgroundColor: backgroundColor
        }} onClick={onClickEvent}>
            <p className={styles.ctaButtonText} style={{
                color: textColor
            }}>
                {text}
            </p>
        </div>
    )
}

export default CTAButton;