import React from "react";
import styles from './Main.module.css';
import Header from "../../components/Header/Header";
import MainSection from "../../components/sections/MainSection/MainSection";
import AchievementSection from "../../components/sections/AchievementSection/AchievementSection";
import ProductSection from "../../components/sections/ProductSection/ProductSection";
import CollaboratorSection from "../../components/sections/CollaboratorSection/CollaboratorSection";
import FoundingSection from "../../components/sections/FoundingSection/FoundingSection";
import NewsSection from "../../components/sections/NewsSection/NewsSection";
import FunctionSection from "../../components/sections/FunctionSection/FunctionSection";
import Footer from "../../components/Footer/Footer";

const Main = () => {
    return (
        <div className={styles.mainPage}>
            <Header />
            <MainSection />
            <AchievementSection />
            <ProductSection />
            <CollaboratorSection />
            <FoundingSection />
            <NewsSection />
            <FunctionSection />
            <Footer />
        </div>
    );
};

export default Main;