import React from "react";
import styles from './FunctionSection.module.css';

import Function0 from '../../../assets/images/functionSection/Function-0.png';
import Function1 from '../../../assets/images/functionSection/Function-1.png';
import Function2 from '../../../assets/images/functionSection/Function-2.png';
import Function3 from '../../../assets/images/functionSection/Function-3.png';
import Function4 from '../../../assets/images/functionSection/Function-4.png';
import Function5 from '../../../assets/images/functionSection/Function-5.png';
import Function6 from '../../../assets/images/functionSection/Function-6.png';
import Function7 from '../../../assets/images/functionSection/Function-7.png';
import Function8 from '../../../assets/images/functionSection/Function-8.png';
import Function9 from '../../../assets/images/functionSection/Function-9.png';
import Function10 from '../../../assets/images/functionSection/Function-10.png';

const FunctionSection = () => {
    const images = [
        Function0,
        Function1,
        Function2,
        Function3,
        Function4,
        Function5,
        Function6,
        Function7,
        Function8,
        Function9,
        Function10,
    ];

    return (
        <div className={styles.functionSection}>
            <p className={styles.title}>
                인톡에서 할 수 있는 일들
            </p>
            <div className={styles.grid}>
                {images.map((image, index) => (
                    <div key={index} className={styles.gridItem}>
                        <img src={image} alt={`Function ${index}`} />
                    </div>
                ))}
                <div className={styles.lastBlock}>
                    <p className={styles.ask}>
                        이 기능들을 이용해보고 싶나요?
                    </p>
                    <div className={styles.goToKakaoButton} onClick={() => { window.location.href = 'https://pf.kakao.com/_ChIBxj'; }}>
                        도입 문의하기
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FunctionSection;