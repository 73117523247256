import React from "react";
import styles from './Footer.module.css';

const Footer = () => {
    const isMobile = window.innerWidth <= 768;

    return (
        <div className={styles.footer}>
            <p className={styles.companyName}>
                intalk
            </p>
            <p className={styles.labName}>
                (주) 인톡 연구소
            </p>
            <div className={styles.container}>
                <p className={styles.ceo}>
                    대표: 유인창
                </p>
                <p className={styles.contact}>
                    +82 10.8408.8640<br />
                    gun.lee8640@gmail.com
                </p>
                <p className={styles.location}>
                    <span style={{ marginRight: '1.2rem', fontWeight: '600' }}>인톡 연구소</span>{isMobile ? <br /> : <></>}서울특별시 마포구 마포대로78, 서울 제2핀테크랩 8층<br />
                    <span style={{ marginRight: '1.2rem', fontWeight: '600', marginTop: isMobile ? '1.2rem' : '0' }}>전주 본사</span>{isMobile ? <br /> : <></>}전라북도 전주시 덕진구 백제대로 563-1 오렌지플래닛<br />
                    <span style={{ marginRight: '1.2rem', fontWeight: '600', marginTop: isMobile ? '1.2rem' : '0' }}>대전 지사</span>{isMobile ? <br /> : <></>}유성구 계룡로 105번길 15 한진오피스텔 203-3호
                </p>
            </div>
        </div>
    )
}

export default Footer;