import React from "react";
import styles from './Header.module.css';
import Logo from '../../assets/images/header/Logo.png';

const Header = () => {
    return (
        <div className={styles.header}>
            <img className={styles.logo} src={Logo}/>
            <div className={styles.blank}/>
            <div className={styles.ctaButton}>
                <p className={styles.ctaButtonText}>
                    문의하기
                </p>
            </div>
        </div>
    );
}

export default Header;