import React from "react";
import styles from './MainSection.module.css';
import CTAButton from '../../buttons/CTAButton/CTAButton';
import Arrow from '../../../assets/images/mainSection/Arrow.png';
import Logo from '../../../assets/images/mainSection/Logo.png';
import Ui from '../../../assets/images/mainSection/Ui.png';

const MainSection = () => {
    return (
        <div className={styles.mainSection}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <img className={styles.logo} src={Logo}/>
                    <p className={styles.slogan}>인공지능 금융 Agent의 Mega-Trend,</p>
                    <p className={styles.title}>인톡을 가장 빠르게<br />도입하는 방법</p>
                    <CTAButton
                        text="방법 알아보기"
                        backgroundColor="var(--intalk-primary-normal)"
                        textColor="var(--intalk-text-normal)"
                        onClickEvent={() => {window.location.href = 'https://pf.kakao.com/_ChIBxj';}}
                    />
                    <div style={{marginBottom: '4rem'}}/>
                </div>
                <div className={styles.blank}/>
                <div className={styles.imageContainer}>
                    <img className={styles.ui} src={Ui}/>
                </div>
            </div>
            <div className={styles.scrollInfoContainer}>
                <p className={styles.scrollInfoText}>
                    스크롤로 인톡에 대해 자세히 알아보세요
                </p>
                <img className={styles.scrollArrow} src={Arrow}/>
            </div>
        </div>
    );
}

export default MainSection;