import React from "react";
import styles from './AchievementSection.module.css';
import RnD from '../../../assets/images/achievementSection/RnD.png';
import Patent from '../../../assets/images/achievementSection/Patent.png';
import Graph from '../../../assets/images/achievementSection/Graph.png';

const AchievementSection = () => {

    return (
        <div className={styles.achievementSection}>
            <div className={styles.container}>
                <div className={styles.item}>
                    <img className={styles.rndIcon} src={RnD}/>
                    <p className={styles.title}>
                        R&D 선정
                    </p>
                    <p className={styles.description}>
                        하이퍼파라미터 최적화를 통한<br />
                        금융데이터 기반 재무 추천 시스템<br />
                        알고리즘 최적화 및<br />
                        성능 강화 RAG 프로세스
                    </p>
                </div>
                <div className={styles.item}>
                    <img className={styles.patentIcon} src={Patent} />
                    <p className={styles.title}>
                        금융 AI 특허 10여건
                    </p>
                    <p className={styles.description}>
                        AI 모델을 이용한 생애 주기에 따른<br />
                        재무설계 시뮬레이션 서비스를 제공하는 서버 및<br />
                        이를 이용한 재무설계 시뮬레이션<br />
                        서비스 제공 방법<br /><br/>
                        <span style={{fontSize: '1.2rem'}}>출원번호: 10-2023-0121507</span>
                    </p>
                </div>
                <div className={styles.item}>
                    <img className={styles.graphIcon} src={Graph}/>
                    <p className={styles.title}>
                        성과
                    </p>
                    <p className={styles.description}>
                        <span style={{fontWeight: 'bold'}}>Pre-Seed 투자유치(24.06)<br />
                            2024 D-테스트베드 사업</span> 외 5건 사업 선정<br />
                        핀테크 챌린지(카카오뱅크) 우수상<br />
                        국내 AC로부터 pre-Seed 투자유치<br />
                        글로벌 AC 앤틀러 5기 진행중
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AchievementSection;