import React, { useState, useEffect } from "react";
import styles from './CollaboratorSection.module.css';
import Logo0 from '../../../assets/images/collaboratorSection/Logo-0.png';
import Logo1 from '../../../assets/images/collaboratorSection/Logo-1.png';
import Logo2 from '../../../assets/images/collaboratorSection/Logo-2.png';
import Logo3 from '../../../assets/images/collaboratorSection/Logo-3.png';
import Logo4 from '../../../assets/images/collaboratorSection/Logo-4.png';
import Logo5 from '../../../assets/images/collaboratorSection/Logo-5.png';
import Logo6 from '../../../assets/images/collaboratorSection/Logo-6.png';
import Logo7 from '../../../assets/images/collaboratorSection/Logo-7.png';
import Logo8 from '../../../assets/images/collaboratorSection/Logo-8.png';
import Logo9 from '../../../assets/images/collaboratorSection/Logo-9.png';
import Logo10 from '../../../assets/images/collaboratorSection/Logo-10.png';
import Logo11 from '../../../assets/images/collaboratorSection/Logo-11.png';
import Logo12 from '../../../assets/images/collaboratorSection/Logo-12.png';
import Logo13 from '../../../assets/images/collaboratorSection/Logo-13.png';

const CollaboratorSection = () => {
    const logos = [
        { src: Logo0, alt: '금융감독원' },
        { src: Logo1, alt: '금융위원회' },
        { src: Logo2, alt: 'iM라이프' },
        { src: Logo3, alt: 'NICE신용평가' },
        { src: Logo4, alt: '한국핀테크지원센터' },
        { src: Logo5, alt: '제2서울핀테크랩' },
        { src: Logo6, alt: 'Green Ribbon' },
        { src: Logo7, alt: '전북창조경제혁신센터' },
        { src: Logo8, alt: 'Orange Planet' },
        { src: Logo9, alt: 'K Data' },
        { src: Logo10, alt: '경상남도빅데이터센터' },
        { src: Logo11, alt: '금융규제 샌드박스' },
        { src: Logo12, alt: 'AI 학습장' },
        { src: Logo13, alt: '한국신용정보원' }
    ];

    const [rows, setRows] = useState([
        logos.slice(0, 4),
        logos.slice(4, 7),
        logos.slice(7, 11),
        logos.slice(11, 14),
    ]);

    useEffect(() => {
        const updateRows = () => {
            const isMobile = window.innerWidth <= 768;
            if (isMobile) {
                const itemsPerRow = 3;
                const newRows = [];
                for (let i = 0; i < logos.length; i += itemsPerRow) {
                    newRows.push(logos.slice(i, i + itemsPerRow));
                }
                setRows(newRows);
            } else {
                setRows([
                    logos.slice(0, 4),
                    logos.slice(4, 7),
                    logos.slice(7, 11),
                    logos.slice(11, 14),
                ])
            }
        };

        updateRows();
        window.addEventListener("resize", updateRows);
        return () => window.removeEventListener("resize", updateRows);
    }, []);

    return (
        <div className={styles.collaboratorSection}>
            <p className={styles.title}>인톡과 함께하는 협업사</p>
            <div className={styles.logoContainer}>
                {rows.map((row, rowIndex) => (
                    <div key={rowIndex} className={styles.row}>
                        {row.map((logo, index) => (
                            <div key={index} className={styles.gridItem}>
                                <img src={logo.src} alt={logo.alt} className={styles.logo} />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CollaboratorSection;