import React from "react";
import styles from './ProductSection.module.css';
import IntalkLogo from '../../../assets/images/productSection/IntalkLogo.png';
import IntalkUi from '../../../assets/images/productSection/IntalkUi.png';
import RoleplayLogo from '../../../assets/images/productSection/RoleplayLogo.png';
import RoleplayUi from '../../../assets/images/productSection/RoleplayUi.png';
import CTAButton from '../../buttons/CTAButton/CTAButton';

const ProductSection = () => {
    return (
        <div className={styles.productSection}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <img className={styles.logo} src={IntalkLogo} />
                    <p className={styles.slogan}>Our Product 1</p>
                    <p className={styles.title}>재무 설계 챗봇</p>
                    <p className={styles.description}>
                        AI가 여러분들의<br />
                        재무 설계를 해드릴게요</p>
                    <CTAButton
                        text="도입 문의하기"
                        backgroundColor="var(--intalk-primary-normal)"
                        textColor="var(--intalk-text-normal)"
                        onClickEvent={() => { window.location.href = 'https://pf.kakao.com/_ChIBxj'; }}
                    />
                    <div style={{ marginBottom: '4rem' }} />
                </div>
                <div className={styles.blank} />
                <div className={styles.imageContainer}>
                    <img className={styles.ui} src={IntalkUi} />
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.content}>
                    <img className={styles.logo} src={RoleplayLogo} />
                    <p className={styles.slogan}>Our Product 2</p>
                    <p className={styles.title} style={{color: "var(--primary-normal)"}}>Role-Play</p>
                    <p className={styles.description}>
                        보험 설계사의 영업을<br />
                        서포트해드릴게요</p>
                    <CTAButton
                        text="시작하기"
                        backgroundColor="var(--primary-normal)"
                        textColor="var(--static-white)"
                        onClickEvent={() => { window.location.href = 'https://pf.kakao.com/_ChIBxj'; }}
                    />
                    <div style={{ marginBottom: '4rem' }} />
                </div>
                <div className={styles.blank} />
                <div className={styles.imageContainer}>
                    <img className={styles.ui} src={RoleplayUi} />
                </div>
            </div>
        </div>
    );
}

export default ProductSection;