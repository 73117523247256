import React from "react";
import styles from './FoundingSection.module.css';

const FoundingSection = () => {
    return (
        <div className={styles.foundingSection}>
            <div className={styles.container}>
                <div className={styles.item}>
                    <div className={styles.title}>
                        AI & 인간 존중
                    </div>
                    <div className={styles.description}>
                        AI와 데이터로 시대를 리드하며<br />
                        글로벌 최고의 인재들이 모인<br />
                        핀테크 회사
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.title}>
                        사람을 위한 금융
                    </div>
                    <div className={styles.description}>
                        금융 중개자의 효율성 문제를 풀고<br />
                        돈이 사람을 위해<br />
                        일하도록 만드는 회사
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.title}>
                        금융을 AI로 재정의
                    </div>
                    <div className={styles.description}>
                        Ai, 비용 효율화를 통하여<br />
                        금융 지식격차와 돈의 고민을 줄이고<br />
                        삶에 더욱 집중하는 세상을 만든다
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FoundingSection;